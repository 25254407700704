:root:root {
  --adm-color-primary: #4374e2;
  --active-title-color: #4374e2;
}
div::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
div {
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}
