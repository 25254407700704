.component-tabbar .module-tabbar {
  display: flex;
  box-sizing: border-box;
  padding: 2.13vw;
}
.component-tabbar .module-tabbar .tabbar-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
}
.component-tabbar .module-tabbar .tabbar-item .icon {
  width: 5.33vw;
  height: 5.33vw;
}
.component-tabbar .module-tabbar .tabbar-item .icon .img-icon {
  width: 100%;
  height: 100%;
}
.component-tabbar .module-tabbar .tabbar-item .text {
  margin-top: 0.8vw;
  font-size: 3.2vw;
  line-height: 5.33vw;
}
