.component-with-tabbar .part-outlet {
  box-sizing: border-box;
  padding-bottom: 23.47vw;
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.component-with-tabbar .part-tabbar {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
}
